import React from 'react';
import isPlainObject from 'lodash/isPlainObject';

var ApiClientContext = React.createContext({
  getApiClient: null
});
function ApiClientProvider(_ref) {
  var getApiClient = _ref.getApiClient,
      children = _ref.children;
  // don't use jsx
  return React.createElement(ApiClientContext.Provider, {
    value: {
      getApiClient: getApiClient
    }
  }, children);
}
function useApiClient() {
  return React.useContext(ApiClientContext);
}

/* eslint-disable import/prefer-default-export */
/**
 * For the queries managed by generated hooks, the very first entry in the queryKey
 * is `@@${apiName}` (prepend with '@@').
 *
 * `apiName` is then injected into `getApiClient` so that you could return
 * the corresponding http client.
 */

function extractInfosFromQueryKey(queryKey) {
  var apiName = queryKey[0],
      queryKeyRest = queryKey.slice(1);

  if (typeof apiName === 'string' && apiName.startsWith('@@')) {
    return {
      apiName: apiName.replace('@@', ''),
      queryKeyRest: queryKeyRest
    };
  } // We return null in the case this query is not managed by generated query-hooks


  return null;
}
/**
 * This factory returns a default function for `queryFn` that handles the react-query key
 * the way it is intended for the generator to be consumed to create get requests.
 *
 * Example of use:
 *
```js
new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: makeQueryFn({ getApiClient })
      }
    }
})
```
 */


function makeQueryFn(_ref) {
  var getApiClient = _ref.getApiClient;
  return function (_ref2) {
    var queryKey = _ref2.queryKey,
        signal = _ref2.signal;
    var queryKeyInfos = extractInfosFromQueryKey(queryKey);

    if (queryKeyInfos) {
      var _queryKeyInfos$queryK = queryKeyInfos.queryKeyRest.reduce(function (acc, cur) {
        if (isPlainObject(cur)) {
          Object.assign(acc.queryParams, cur);
        } else if (!(typeof cur === 'string' && cur.startsWith(':')) // ignore ":foo" like keys that are only used in queryKey to force refresh, but not in the url
        ) {
          acc.queryStringSegments.push(cur);
        }

        return acc;
      }, {
        queryStringSegments: [],
        queryParams: {}
      }),
          queryStringSegments = _queryKeyInfos$queryK.queryStringSegments,
          queryParams = _queryKeyInfos$queryK.queryParams;

      return getApiClient({
        apiName: queryKeyInfos.apiName
      }).get(queryStringSegments.join('/'), {
        params: queryParams,
        signal: signal // https://tanstack.com/query/v4/docs/react/guides/query-cancellation#using-axios-v0220

      }).then(function (_ref3) {
        var data = _ref3.data;
        return data;
      });
    } // That way, we ensure the request with your apiClient will only be made
    // on react-query generated hook queryKeys.

  };
}

export { ApiClientProvider, makeQueryFn, useApiClient };
