import * as React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { Box, Typography } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import Link from 'next/link';
import { VectorLogoBCB } from '@maiia/design-system';
import getRouteName from '@/components/utils/getRouteName';

import Header from '../../organisms/Header/Header';
import HeaderPro from '../../organisms/Header/HeaderPro';
import Footer from '../../organisms/Footer/Footer';

import OpenTokSession from '../TlcSession/OpenTokSession';
import {
  useIsPartners,
  exceptionPagePartners,
} from '@/src/hooks/useIsPartners';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';
import useBcbWebview from '@/src/hooks/useBcbWebview';

const MiniOpenTok = dynamic(() => import('../TlcSession/MiniOpenTok'), {
  ssr: false,
});

const TopNotification = dynamic(
  () => import('../../molecules/Notification/Notification'),
  {
    ssr: false,
  },
);

const StyledMain = styled(Box)(
  ({ theme }) => css`
    &[id^='medicaments-claude-bernard'] {
      .header,
      .footer-support,
      .footer:not(.dynamic) {
        background: ${theme.palette.background.kiiwi};
      }
    }
  `,
);

type Props = {
  children: React.ReactNode;
};

const Main = ({ children }: Props) => {
  const router = useRouter();
  const isPartners = useIsPartners();
  const routeName = getRouteName(router.pathname);
  const isOfferPage = router.asPath === '/pro';
  const simpleLayout = React.useMemo(
    () =>
      [
        APP_ROUTES.WELCOME_TERMINAL.CENTER_ID,
        APP_ROUTES.LINK_ACCOUNT.VERIFY,
        APP_ROUTES.ANONYMOUS.ID,
        APP_ROUTES.ANONYMOUS.ID_LOGIN,
        '/[speciality]/[locality]/[slug]/rdv-rapide',
        '/design-system/accueil',
        '/design-system/accueil/birthday',
        '/design-system/accueil/name',
        '/design-system/accueil/confirmation',
        '/design-system/accueil/error',
        APP_ROUTES.APPOINTMENT_LITE,
        ...(isPartners ? exceptionPagePartners : []),
        APP_ROUTES.TLC.SESSION_ID,
      ].includes(router.pathname),
    [router.pathname, isPartners],
  );

  const simpleLayoutHeaderExceptions = React.useMemo(
    () => [APP_ROUTES.TLC.SESSION_ID].includes(router.pathname),
    [router.pathname],
  );

  const { t } = useTranslation();
  const { isActive: isBcbWebview } = useBcbWebview();

  // Specific case for the mobile webview
  if (isBcbWebview) {
    return (
      <StyledMain id={routeName}>
        <Box
          className="header"
          sx={theme => ({
            justifyContent: 'center!important',
            svg: {
              height: theme.spacing(4),
            },
          })}
        >
          <Link href={APP_ROUTES.MEDICAMENTS.BASE_URL} passHref>
            <Typography
              component="a"
              sx={theme => ({
                color: theme.palette.primary.contrastText,
              })}
              title={t('seo-title_directory_medicaments')}
              aria-label={t('seo-title_directory_medicaments')}
            >
              <VectorLogoBCB role="img" alt={t('drugs-menu')} />
            </Typography>
          </Link>
        </Box>
        {children}
      </StyledMain>
    );
  }

  // We need to pass page props to dynamic footer (eg. articles)
  const pageProps =
    router.pathname === APP_ROUTES.SPECIALITY.LOCALITY
      ? children?.[0]?.props
      : undefined;

  return (
    // TODO(nampnq): pageId
    <StyledMain id={routeName} className={clsx(isPartners && 'white-label')}>
      {(!simpleLayout || simpleLayoutHeaderExceptions) && (
        <>
          {isOfferPage ? (
            // TODO: looks not used anymore as /pro is redirected to an other website ??
            <HeaderPro />
          ) : (
            <Header
              variant={routeName === 'search' ? 'with-searchbar' : undefined}
            />
          )}
        </>
      )}

      <TopNotification />
      <OpenTokSession>
        <MiniOpenTok />
        {children}
      </OpenTokSession>
      {!simpleLayout && <Footer pageProps={pageProps} />}
    </StyledMain>
  );
};

export default Main;
