/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Implementation for `patPublicApplicationSettingsFeatureProperties` hook
 * Endpoint: `/pat-public/applicationSettings/feature-properties`
 */

import { useQueryClient, useQuery } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

export function makeQueryKey() {
	return [
		"@@api-patient",
		"pat-public",
		"applicationSettings",
		"feature-properties",
	];
}

export function useInvalidateQuery() {
	const queryClient = useQueryClient();
	return function invalidateQuery() {
		return queryClient.invalidateQueries({
			queryKey: [
				"@@api-patient",
				"pat-public",
				"applicationSettings",
				"feature-properties",
			].filter(Boolean),
		});
	};
}

export function useGet(options) {
	return useQuery({
		...options,
		queryKey: makeQueryKey(),
	});
}
